:root {
  --grey: #232325;
  --spot: #ffffff;
  --parts-columns: 5;
  --parts-radius: 0%;
  --animation-speed: 1s;
}

.particles {
  position: relative;
  transition: all var(--animation-speed);
}
.particles__content {
  position: relative;
  z-index: 2;
}
.particles__parts {
  display: grid;
  grid-template-columns: repeat(
    auto-fill,
    minmax(calc(100% / var(--parts-columns)), auto)
  );
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  transition: all var(--animation-speed);
  pointer-events: none;
}
.particles__parts > * {
  transition: all var(--animation-speed) cubic-bezier(0.57, 0.55, 0.31, 1);
  background: var(--grey);
}
.particles.hover {
  /* scale: 0; */
  opacity: 0;
}
.particles.hover .particles__parts > * {
  border-radius: var(--parts-radius);
}
.particles.hover .particles__parts > *:nth-child(0) {
  scale: 0.4;
  rotate: 0deg;
  transform: skew(65deg);
  filter: blur(0.5px);
  translate: -186% -186%;
}
.particles.hover .particles__parts > *:nth-child(0):nth-child(2n + 2) {
  translate: 186% -186%;
}
.particles.hover .particles__parts > *:nth-child(0):nth-child(3n + 3) {
  translate: -186% 186%;
}
.particles.hover .particles__parts > *:nth-child(0):nth-child(4n + 4) {
  translate: 186% 186%;
}
.particles.hover .particles__parts > *:nth-child(1) {
  scale: 0.2;
  rotate: 156deg;
  transform: skew(26deg);
  filter: blur(1px);
  translate: -345% -345%;
}
.particles.hover .particles__parts > *:nth-child(1):nth-child(2n + 2) {
  translate: 345% -345%;
}
.particles.hover .particles__parts > *:nth-child(1):nth-child(3n + 3) {
  translate: -345% 345%;
}
.particles.hover .particles__parts > *:nth-child(1):nth-child(4n + 4) {
  translate: 345% 345%;
}
.particles.hover .particles__parts > *:nth-child(2) {
  scale: 0.6;
  rotate: 0deg;
  transform: skew(60deg);
  filter: blur(1px);
  translate: -768% -768%;
}
.particles.hover .particles__parts > *:nth-child(2):nth-child(2n + 2) {
  translate: 768% -768%;
}
.particles.hover .particles__parts > *:nth-child(2):nth-child(3n + 3) {
  translate: -768% 768%;
}
.particles.hover .particles__parts > *:nth-child(2):nth-child(4n + 4) {
  translate: 768% 768%;
}
.particles.hover .particles__parts > *:nth-child(3) {
  scale: 0.4;
  rotate: 0deg;
  transform: skew(23deg);
  filter: blur(1.5px);
  translate: -624% -624%;
}
.particles.hover .particles__parts > *:nth-child(3):nth-child(2n + 2) {
  translate: 624% -624%;
}
.particles.hover .particles__parts > *:nth-child(3):nth-child(3n + 3) {
  translate: -624% 624%;
}
.particles.hover .particles__parts > *:nth-child(3):nth-child(4n + 4) {
  translate: 624% 624%;
}
.particles.hover .particles__parts > *:nth-child(4) {
  scale: 0.3;
  rotate: 0deg;
  transform: skew(30deg);
  filter: blur(1px);
  translate: -726% -726%;
}
.particles.hover .particles__parts > *:nth-child(4):nth-child(2n + 2) {
  translate: 726% -726%;
}
.particles.hover .particles__parts > *:nth-child(4):nth-child(3n + 3) {
  translate: -726% 726%;
}
.particles.hover .particles__parts > *:nth-child(4):nth-child(4n + 4) {
  translate: 726% 726%;
}
.particles.hover .particles__parts > *:nth-child(5) {
  scale: 0.6;
  rotate: -131deg;
  transform: skew(15deg);
  filter: blur(1.5px);
  translate: -196% -196%;
}
.particles.hover .particles__parts > *:nth-child(5):nth-child(2n + 2) {
  translate: 196% -196%;
}
.particles.hover .particles__parts > *:nth-child(5):nth-child(3n + 3) {
  translate: -196% 196%;
}
.particles.hover .particles__parts > *:nth-child(5):nth-child(4n + 4) {
  translate: 196% 196%;
}
.particles.hover .particles__parts > *:nth-child(6) {
  scale: 0.5;
  rotate: 193deg;
  transform: skew(24deg);
  filter: blur(1px);
  translate: -1075% -1075%;
}
.particles.hover .particles__parts > *:nth-child(6):nth-child(2n + 2) {
  translate: 1075% -1075%;
}
.particles.hover .particles__parts > *:nth-child(6):nth-child(3n + 3) {
  translate: -1075% 1075%;
}
.particles.hover .particles__parts > *:nth-child(6):nth-child(4n + 4) {
  translate: 1075% 1075%;
}
.particles.hover .particles__parts > *:nth-child(7) {
  scale: 0.2;
  rotate: -332deg;
  transform: skew(41deg);
  filter: blur(0.5px);
  translate: -575% -575%;
}
.particles.hover .particles__parts > *:nth-child(7):nth-child(2n + 2) {
  translate: 575% -575%;
}
.particles.hover .particles__parts > *:nth-child(7):nth-child(3n + 3) {
  translate: -575% 575%;
}
.particles.hover .particles__parts > *:nth-child(7):nth-child(4n + 4) {
  translate: 575% 575%;
}
.particles.hover .particles__parts > *:nth-child(8) {
  scale: 0.7;
  rotate: 0deg;
  transform: skew(13deg);
  filter: blur(1px);
  translate: -248% -248%;
}
.particles.hover .particles__parts > *:nth-child(8):nth-child(2n + 2) {
  translate: 248% -248%;
}
.particles.hover .particles__parts > *:nth-child(8):nth-child(3n + 3) {
  translate: -248% 248%;
}
.particles.hover .particles__parts > *:nth-child(8):nth-child(4n + 4) {
  translate: 248% 248%;
}
.particles.hover .particles__parts > *:nth-child(9) {
  scale: 0.1;
  rotate: 295deg;
  transform: skew(66deg);
  filter: blur(1.5px);
  translate: -974% -974%;
}
.particles.hover .particles__parts > *:nth-child(9):nth-child(2n + 2) {
  translate: 974% -974%;
}
.particles.hover .particles__parts > *:nth-child(9):nth-child(3n + 3) {
  translate: -974% 974%;
}
.particles.hover .particles__parts > *:nth-child(9):nth-child(4n + 4) {
  translate: 974% 974%;
}
.particles.hover .particles__parts > *:nth-child(10) {
  scale: 0.2;
  rotate: -374deg;
  transform: skew(53deg);
  filter: blur(1.5px);
  translate: -1018% -1018%;
}
.particles.hover .particles__parts > *:nth-child(10):nth-child(2n + 2) {
  translate: 1018% -1018%;
}
.particles.hover .particles__parts > *:nth-child(10):nth-child(3n + 3) {
  translate: -1018% 1018%;
}
.particles.hover .particles__parts > *:nth-child(10):nth-child(4n + 4) {
  translate: 1018% 1018%;
}
.particles.hover .particles__parts > *:nth-child(11) {
  scale: 0.9;
  rotate: 257deg;
  transform: skew(43deg);
  filter: blur(1px);
  translate: -915% -915%;
}
.particles.hover .particles__parts > *:nth-child(11):nth-child(2n + 2) {
  translate: 915% -915%;
}
.particles.hover .particles__parts > *:nth-child(11):nth-child(3n + 3) {
  translate: -915% 915%;
}
.particles.hover .particles__parts > *:nth-child(11):nth-child(4n + 4) {
  translate: 915% 915%;
}
.particles.hover .particles__parts > *:nth-child(12) {
  scale: 1;
  rotate: 0deg;
  transform: skew(48deg);
  filter: blur(1px);
  translate: -391% -391%;
}
.particles.hover .particles__parts > *:nth-child(12):nth-child(2n + 2) {
  translate: 391% -391%;
}
.particles.hover .particles__parts > *:nth-child(12):nth-child(3n + 3) {
  translate: -391% 391%;
}
.particles.hover .particles__parts > *:nth-child(12):nth-child(4n + 4) {
  translate: 391% 391%;
}
.particles.hover .particles__parts > *:nth-child(13) {
  scale: 0.4;
  rotate: 0deg;
  transform: skew(30deg);
  filter: blur(0.5px);
  translate: -681% -681%;
}
.particles.hover .particles__parts > *:nth-child(13):nth-child(2n + 2) {
  translate: 681% -681%;
}
.particles.hover .particles__parts > *:nth-child(13):nth-child(3n + 3) {
  translate: -681% 681%;
}
.particles.hover .particles__parts > *:nth-child(13):nth-child(4n + 4) {
  translate: 681% 681%;
}
.particles.hover .particles__parts > *:nth-child(14) {
  scale: 0.9;
  rotate: 143deg;
  transform: skew(55deg);
  filter: blur(1.5px);
  translate: -648% -648%;
}
.particles.hover .particles__parts > *:nth-child(14):nth-child(2n + 2) {
  translate: 648% -648%;
}
.particles.hover .particles__parts > *:nth-child(14):nth-child(3n + 3) {
  translate: -648% 648%;
}
.particles.hover .particles__parts > *:nth-child(14):nth-child(4n + 4) {
  translate: 648% 648%;
}
.particles.hover .particles__parts > *:nth-child(15) {
  scale: 0.9;
  rotate: 0deg;
  transform: skew(16deg);
  filter: blur(1px);
  translate: -633% -633%;
}
.particles.hover .particles__parts > *:nth-child(15):nth-child(2n + 2) {
  translate: 633% -633%;
}
.particles.hover .particles__parts > *:nth-child(15):nth-child(3n + 3) {
  translate: -633% 633%;
}
.particles.hover .particles__parts > *:nth-child(15):nth-child(4n + 4) {
  translate: 633% 633%;
}
.particles.hover .particles__parts > *:nth-child(16) {
  scale: 0.8;
  rotate: 0deg;
  transform: skew(23deg);
  filter: blur(1px);
  translate: -262% -262%;
}
.particles.hover .particles__parts > *:nth-child(16):nth-child(2n + 2) {
  translate: 262% -262%;
}
.particles.hover .particles__parts > *:nth-child(16):nth-child(3n + 3) {
  translate: -262% 262%;
}
.particles.hover .particles__parts > *:nth-child(16):nth-child(4n + 4) {
  translate: 262% 262%;
}
.particles.hover .particles__parts > *:nth-child(17) {
  scale: 0.8;
  rotate: -102deg;
  transform: skew(61deg);
  filter: blur(1px);
  translate: -1031% -1031%;
}
.particles.hover .particles__parts > *:nth-child(17):nth-child(2n + 2) {
  translate: 1031% -1031%;
}
.particles.hover .particles__parts > *:nth-child(17):nth-child(3n + 3) {
  translate: -1031% 1031%;
}
.particles.hover .particles__parts > *:nth-child(17):nth-child(4n + 4) {
  translate: 1031% 1031%;
}
.particles.hover .particles__parts > *:nth-child(18) {
  scale: 1;
  rotate: -293deg;
  transform: skew(36deg);
  filter: blur(1px);
  translate: -788% -788%;
}
.particles.hover .particles__parts > *:nth-child(18):nth-child(2n + 2) {
  translate: 788% -788%;
}
.particles.hover .particles__parts > *:nth-child(18):nth-child(3n + 3) {
  translate: -788% 788%;
}
.particles.hover .particles__parts > *:nth-child(18):nth-child(4n + 4) {
  translate: 788% 788%;
}
.particles.hover .particles__parts > *:nth-child(19) {
  scale: 0.2;
  rotate: -121deg;
  transform: skew(39deg);
  filter: blur(0.5px);
  translate: -316% -316%;
}
.particles.hover .particles__parts > *:nth-child(19):nth-child(2n + 2) {
  translate: 316% -316%;
}
.particles.hover .particles__parts > *:nth-child(19):nth-child(3n + 3) {
  translate: -316% 316%;
}
.particles.hover .particles__parts > *:nth-child(19):nth-child(4n + 4) {
  translate: 316% 316%;
}
.particles.hover .particles__parts > *:nth-child(20) {
  scale: 0.4;
  rotate: -191deg;
  transform: skew(20deg);
  filter: blur(0.5px);
  translate: -216% -216%;
}
.particles.hover .particles__parts > *:nth-child(20):nth-child(2n + 2) {
  translate: 216% -216%;
}
.particles.hover .particles__parts > *:nth-child(20):nth-child(3n + 3) {
  translate: -216% 216%;
}
.particles.hover .particles__parts > *:nth-child(20):nth-child(4n + 4) {
  translate: 216% 216%;
}
.particles.hover .particles__parts > *:nth-child(21) {
  scale: 1;
  rotate: 416deg;
  transform: skew(47deg);
  filter: blur(1px);
  translate: -1070% -1070%;
}
.particles.hover .particles__parts > *:nth-child(21):nth-child(2n + 2) {
  translate: 1070% -1070%;
}
.particles.hover .particles__parts > *:nth-child(21):nth-child(3n + 3) {
  translate: -1070% 1070%;
}
.particles.hover .particles__parts > *:nth-child(21):nth-child(4n + 4) {
  translate: 1070% 1070%;
}
.particles.hover .particles__parts > *:nth-child(22) {
  scale: 1;
  rotate: 396deg;
  transform: skew(41deg);
  filter: blur(0.5px);
  translate: -618% -618%;
}
.particles.hover .particles__parts > *:nth-child(22):nth-child(2n + 2) {
  translate: 618% -618%;
}
.particles.hover .particles__parts > *:nth-child(22):nth-child(3n + 3) {
  translate: -618% 618%;
}
.particles.hover .particles__parts > *:nth-child(22):nth-child(4n + 4) {
  translate: 618% 618%;
}
.particles.hover .particles__parts > *:nth-child(23) {
  scale: 0.7;
  rotate: -194deg;
  transform: skew(23deg);
  filter: blur(1px);
  translate: -365% -365%;
}
.particles.hover .particles__parts > *:nth-child(23):nth-child(2n + 2) {
  translate: 365% -365%;
}
.particles.hover .particles__parts > *:nth-child(23):nth-child(3n + 3) {
  translate: -365% 365%;
}
.particles.hover .particles__parts > *:nth-child(23):nth-child(4n + 4) {
  translate: 365% 365%;
}
.particles.hover .particles__parts > *:nth-child(24) {
  scale: 0.2;
  rotate: 0deg;
  transform: skew(27deg);
  filter: blur(1.5px);
  translate: -252% -252%;
}
.particles.hover .particles__parts > *:nth-child(24):nth-child(2n + 2) {
  translate: 252% -252%;
}
.particles.hover .particles__parts > *:nth-child(24):nth-child(3n + 3) {
  translate: -252% 252%;
}
.particles.hover .particles__parts > *:nth-child(24):nth-child(4n + 4) {
  translate: 252% 252%;
}
.particles.hover .particles__parts > *:nth-child(25) {
  scale: 0.8;
  rotate: 0deg;
  transform: skew(29deg);
  filter: blur(1.5px);
  translate: -414% -414%;
}
.particles.hover .particles__parts > *:nth-child(25):nth-child(2n + 2) {
  translate: 414% -414%;
}
.particles.hover .particles__parts > *:nth-child(25):nth-child(3n + 3) {
  translate: -414% 414%;
}
.particles.hover .particles__parts > *:nth-child(25):nth-child(4n + 4) {
  translate: 414% 414%;
}
.particles.hover .particles__parts > *:nth-child(26) {
  scale: 0.7;
  rotate: -360deg;
  transform: skew(37deg);
  filter: blur(1px);
  translate: -274% -274%;
}
.particles.hover .particles__parts > *:nth-child(26):nth-child(2n + 2) {
  translate: 274% -274%;
}
.particles.hover .particles__parts > *:nth-child(26):nth-child(3n + 3) {
  translate: -274% 274%;
}
.particles.hover .particles__parts > *:nth-child(26):nth-child(4n + 4) {
  translate: 274% 274%;
}
.particles.hover .particles__parts > *:nth-child(27) {
  scale: 1;
  rotate: -163deg;
  transform: skew(40deg);
  filter: blur(1.5px);
  translate: -909% -909%;
}
.particles.hover .particles__parts > *:nth-child(27):nth-child(2n + 2) {
  translate: 909% -909%;
}
.particles.hover .particles__parts > *:nth-child(27):nth-child(3n + 3) {
  translate: -909% 909%;
}
.particles.hover .particles__parts > *:nth-child(27):nth-child(4n + 4) {
  translate: 909% 909%;
}
.particles.hover .particles__parts > *:nth-child(28) {
  scale: 1;
  rotate: 0deg;
  transform: skew(35deg);
  filter: blur(0.5px);
  translate: -995% -995%;
}
.particles.hover .particles__parts > *:nth-child(28):nth-child(2n + 2) {
  translate: 995% -995%;
}
.particles.hover .particles__parts > *:nth-child(28):nth-child(3n + 3) {
  translate: -995% 995%;
}
.particles.hover .particles__parts > *:nth-child(28):nth-child(4n + 4) {
  translate: 995% 995%;
}
.particles.hover .particles__parts > *:nth-child(29) {
  scale: 0.4;
  rotate: 0deg;
  transform: skew(28deg);
  filter: blur(0.5px);
  translate: -628% -628%;
}
.particles.hover .particles__parts > *:nth-child(29):nth-child(2n + 2) {
  translate: 628% -628%;
}
.particles.hover .particles__parts > *:nth-child(29):nth-child(3n + 3) {
  translate: -628% 628%;
}
.particles.hover .particles__parts > *:nth-child(29):nth-child(4n + 4) {
  translate: 628% 628%;
}
.particles.hover .particles__parts > *:nth-child(30) {
  scale: 0.2;
  rotate: 0deg;
  transform: skew(33deg);
  filter: blur(1px);
  translate: -315% -315%;
}
.particles.hover .particles__parts > *:nth-child(30):nth-child(2n + 2) {
  translate: 315% -315%;
}
.particles.hover .particles__parts > *:nth-child(30):nth-child(3n + 3) {
  translate: -315% 315%;
}
.particles.hover .particles__parts > *:nth-child(30):nth-child(4n + 4) {
  translate: 315% 315%;
}
.particles.hover .particles__parts > *:nth-child(31) {
  scale: 0.7;
  rotate: 371deg;
  transform: skew(51deg);
  filter: blur(1.5px);
  translate: -597% -597%;
}
.particles.hover .particles__parts > *:nth-child(31):nth-child(2n + 2) {
  translate: 597% -597%;
}
.particles.hover .particles__parts > *:nth-child(31):nth-child(3n + 3) {
  translate: -597% 597%;
}
.particles.hover .particles__parts > *:nth-child(31):nth-child(4n + 4) {
  translate: 597% 597%;
}
.particles.hover .particles__parts > *:nth-child(32) {
  scale: 0.2;
  rotate: 298deg;
  transform: skew(68deg);
  filter: blur(1px);
  translate: -391% -391%;
}
.particles.hover .particles__parts > *:nth-child(32):nth-child(2n + 2) {
  translate: 391% -391%;
}
.particles.hover .particles__parts > *:nth-child(32):nth-child(3n + 3) {
  translate: -391% 391%;
}
.particles.hover .particles__parts > *:nth-child(32):nth-child(4n + 4) {
  translate: 391% 391%;
}
.particles.hover .particles__parts > *:nth-child(33) {
  scale: 0.6;
  rotate: 408deg;
  transform: skew(41deg);
  filter: blur(1.5px);
  translate: -995% -995%;
}
.particles.hover .particles__parts > *:nth-child(33):nth-child(2n + 2) {
  translate: 995% -995%;
}
.particles.hover .particles__parts > *:nth-child(33):nth-child(3n + 3) {
  translate: -995% 995%;
}
.particles.hover .particles__parts > *:nth-child(33):nth-child(4n + 4) {
  translate: 995% 995%;
}
.particles.hover .particles__parts > *:nth-child(34) {
  scale: 1;
  rotate: 0deg;
  transform: skew(29deg);
  filter: blur(1px);
  translate: -669% -669%;
}
.particles.hover .particles__parts > *:nth-child(34):nth-child(2n + 2) {
  translate: 669% -669%;
}
.particles.hover .particles__parts > *:nth-child(34):nth-child(3n + 3) {
  translate: -669% 669%;
}
.particles.hover .particles__parts > *:nth-child(34):nth-child(4n + 4) {
  translate: 669% 669%;
}
.particles.hover .particles__parts > *:nth-child(35) {
  scale: 0.3;
  rotate: 162deg;
  transform: skew(51deg);
  filter: blur(0.5px);
  translate: -349% -349%;
}
.particles.hover .particles__parts > *:nth-child(35):nth-child(2n + 2) {
  translate: 349% -349%;
}
.particles.hover .particles__parts > *:nth-child(35):nth-child(3n + 3) {
  translate: -349% 349%;
}
.particles.hover .particles__parts > *:nth-child(35):nth-child(4n + 4) {
  translate: 349% 349%;
}
.particles.hover .particles__parts > *:nth-child(36) {
  scale: 0.4;
  rotate: 0deg;
  transform: skew(36deg);
  filter: blur(1px);
  translate: -1073% -1073%;
}
.particles.hover .particles__parts > *:nth-child(36):nth-child(2n + 2) {
  translate: 1073% -1073%;
}
.particles.hover .particles__parts > *:nth-child(36):nth-child(3n + 3) {
  translate: -1073% 1073%;
}
.particles.hover .particles__parts > *:nth-child(36):nth-child(4n + 4) {
  translate: 1073% 1073%;
}
.particles.hover .particles__parts > *:nth-child(37) {
  scale: 0.7;
  rotate: -413deg;
  transform: skew(12deg);
  filter: blur(1.5px);
  translate: -515% -515%;
}
.particles.hover .particles__parts > *:nth-child(37):nth-child(2n + 2) {
  translate: 515% -515%;
}
.particles.hover .particles__parts > *:nth-child(37):nth-child(3n + 3) {
  translate: -515% 515%;
}
.particles.hover .particles__parts > *:nth-child(37):nth-child(4n + 4) {
  translate: 515% 515%;
}
.particles.hover .particles__parts > *:nth-child(38) {
  scale: 0.2;
  rotate: 0deg;
  transform: skew(59deg);
  filter: blur(1.5px);
  translate: -525% -525%;
}
.particles.hover .particles__parts > *:nth-child(38):nth-child(2n + 2) {
  translate: 525% -525%;
}
.particles.hover .particles__parts > *:nth-child(38):nth-child(3n + 3) {
  translate: -525% 525%;
}
.particles.hover .particles__parts > *:nth-child(38):nth-child(4n + 4) {
  translate: 525% 525%;
}
.particles.hover .particles__parts > *:nth-child(39) {
  scale: 0.9;
  rotate: -434deg;
  transform: skew(61deg);
  filter: blur(1px);
  translate: -443% -443%;
}
.particles.hover .particles__parts > *:nth-child(39):nth-child(2n + 2) {
  translate: 443% -443%;
}
.particles.hover .particles__parts > *:nth-child(39):nth-child(3n + 3) {
  translate: -443% 443%;
}
.particles.hover .particles__parts > *:nth-child(39):nth-child(4n + 4) {
  translate: 443% 443%;
}
.particles.hover .particles__parts > *:nth-child(40) {
  scale: 0.4;
  rotate: -81deg;
  transform: skew(15deg);
  filter: blur(0.5px);
  translate: -380% -380%;
}
.particles.hover .particles__parts > *:nth-child(40):nth-child(2n + 2) {
  translate: 380% -380%;
}
.particles.hover .particles__parts > *:nth-child(40):nth-child(3n + 3) {
  translate: -380% 380%;
}
.particles.hover .particles__parts > *:nth-child(40):nth-child(4n + 4) {
  translate: 380% 380%;
}
.particles.hover .particles__parts > *:nth-child(41) {
  scale: 0.4;
  rotate: -179deg;
  transform: skew(49deg);
  filter: blur(1.5px);
  translate: -501% -501%;
}
.particles.hover .particles__parts > *:nth-child(41):nth-child(2n + 2) {
  translate: 501% -501%;
}
.particles.hover .particles__parts > *:nth-child(41):nth-child(3n + 3) {
  translate: -501% 501%;
}
.particles.hover .particles__parts > *:nth-child(41):nth-child(4n + 4) {
  translate: 501% 501%;
}
.particles.hover .particles__parts > *:nth-child(42) {
  scale: 0.4;
  rotate: 0deg;
  transform: skew(45deg);
  filter: blur(1.5px);
  translate: -1042% -1042%;
}
.particles.hover .particles__parts > *:nth-child(42):nth-child(2n + 2) {
  translate: 1042% -1042%;
}
.particles.hover .particles__parts > *:nth-child(42):nth-child(3n + 3) {
  translate: -1042% 1042%;
}
.particles.hover .particles__parts > *:nth-child(42):nth-child(4n + 4) {
  translate: 1042% 1042%;
}
.particles.hover .particles__parts > *:nth-child(43) {
  scale: 0.1;
  rotate: 271deg;
  transform: skew(13deg);
  filter: blur(0.5px);
  translate: -972% -972%;
}
.particles.hover .particles__parts > *:nth-child(43):nth-child(2n + 2) {
  translate: 972% -972%;
}
.particles.hover .particles__parts > *:nth-child(43):nth-child(3n + 3) {
  translate: -972% 972%;
}
.particles.hover .particles__parts > *:nth-child(43):nth-child(4n + 4) {
  translate: 972% 972%;
}
.particles.hover .particles__parts > *:nth-child(44) {
  scale: 0.1;
  rotate: -268deg;
  transform: skew(42deg);
  filter: blur(1px);
  translate: -331% -331%;
}
.particles.hover .particles__parts > *:nth-child(44):nth-child(2n + 2) {
  translate: 331% -331%;
}
.particles.hover .particles__parts > *:nth-child(44):nth-child(3n + 3) {
  translate: -331% 331%;
}
.particles.hover .particles__parts > *:nth-child(44):nth-child(4n + 4) {
  translate: 331% 331%;
}
.particles.hover .particles__parts > *:nth-child(45) {
  scale: 0.9;
  rotate: -148deg;
  transform: skew(53deg);
  filter: blur(0.5px);
  translate: -707% -707%;
}
.particles.hover .particles__parts > *:nth-child(45):nth-child(2n + 2) {
  translate: 707% -707%;
}
.particles.hover .particles__parts > *:nth-child(45):nth-child(3n + 3) {
  translate: -707% 707%;
}
.particles.hover .particles__parts > *:nth-child(45):nth-child(4n + 4) {
  translate: 707% 707%;
}
.particles.hover .particles__parts > *:nth-child(46) {
  scale: 0.7;
  rotate: 242deg;
  transform: skew(52deg);
  filter: blur(1.5px);
  translate: -904% -904%;
}
.particles.hover .particles__parts > *:nth-child(46):nth-child(2n + 2) {
  translate: 904% -904%;
}
.particles.hover .particles__parts > *:nth-child(46):nth-child(3n + 3) {
  translate: -904% 904%;
}
.particles.hover .particles__parts > *:nth-child(46):nth-child(4n + 4) {
  translate: 904% 904%;
}
.particles.hover .particles__parts > *:nth-child(47) {
  scale: 0.3;
  rotate: 319deg;
  transform: skew(70deg);
  filter: blur(1px);
  translate: -844% -844%;
}
.particles.hover .particles__parts > *:nth-child(47):nth-child(2n + 2) {
  translate: 844% -844%;
}
.particles.hover .particles__parts > *:nth-child(47):nth-child(3n + 3) {
  translate: -844% 844%;
}
.particles.hover .particles__parts > *:nth-child(47):nth-child(4n + 4) {
  translate: 844% 844%;
}
.particles.hover .particles__parts > *:nth-child(48) {
  scale: 0.9;
  rotate: 0deg;
  transform: skew(15deg);
  filter: blur(0.5px);
  translate: -782% -782%;
}
.particles.hover .particles__parts > *:nth-child(48):nth-child(2n + 2) {
  translate: 782% -782%;
}
.particles.hover .particles__parts > *:nth-child(48):nth-child(3n + 3) {
  translate: -782% 782%;
}
.particles.hover .particles__parts > *:nth-child(48):nth-child(4n + 4) {
  translate: 782% 782%;
}
.particles.hover .particles__parts > *:nth-child(49) {
  scale: 1;
  rotate: -123deg;
  transform: skew(52deg);
  filter: blur(0.5px);
  translate: -809% -809%;
}
.particles.hover .particles__parts > *:nth-child(49):nth-child(2n + 2) {
  translate: 809% -809%;
}
.particles.hover .particles__parts > *:nth-child(49):nth-child(3n + 3) {
  translate: -809% 809%;
}
.particles.hover .particles__parts > *:nth-child(49):nth-child(4n + 4) {
  translate: 809% 809%;
}
.particles.hover .particles__parts > *:nth-child(50) {
  scale: 0.8;
  rotate: 314deg;
  transform: skew(65deg);
  filter: blur(1px);
  translate: -908% -908%;
}
.particles.hover .particles__parts > *:nth-child(50):nth-child(2n + 2) {
  translate: 908% -908%;
}
.particles.hover .particles__parts > *:nth-child(50):nth-child(3n + 3) {
  translate: -908% 908%;
}
.particles.hover .particles__parts > *:nth-child(50):nth-child(4n + 4) {
  translate: 908% 908%;
}

.buttons {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

.example-button-styling {
  box-sizing: border-box;
  outline: 0;
  border: 0;
  box-shadow: 0 0 0;
  text-align: center;
  color: var(--spot);
  background: none;
  cursor: pointer;
}

.example-button-styling.hover {
  color: var(--grey);
}
