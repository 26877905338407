.react-time-picker {
  display: inline-flex;
  position: relative;
  border-radius: 100;
}
.react-time-picker,
.react-time-picker *,
.react-time-picker *:before,
.react-time-picker *:after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  /* box-sizing: border-box; */
}
.react-time-picker--disabled {
  background-color: #f0f0f0;
  color: #6d6d6d;
}
.react-time-picker__wrapper {
  display: flex;
  flex-grow: 1;
  flex-shrink: 0;
}
.react-time-picker__inputGroup {
  min-width: calc((4px * 3) + 0.54em * 6 + 0.217em * 2);
  flex-grow: 1;
  padding: 0 2px;
  /* box-sizing: content-box; */
  border-radius: 100;
}
.react-time-picker__inputGroup__divider {
  padding: 1px 0;
  white-space: pre;
}
.react-time-picker__inputGroup__input {
  min-width: 0.54em;
  height: 100%;
  position: relative;
  padding: 0 1px;
  border: 0;
  background: none;
  font: inherit;
  box-sizing: content-box;
  -moz-appearance: textfield;
  border-radius: 100;
}
.react-time-picker__inputGroup__input::-webkit-outer-spin-button,
.react-time-picker__inputGroup__input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.react-time-picker__inputGroup__input:invalid {
  background: rgba(255, 0, 0, 0.1);
}
.react-time-picker__inputGroup__input--hasLeadingZero {
  margin-left: -0.54em;
  padding-left: calc(1px + 0.54em);
}
.react-time-picker__inputGroup__amPm {
  font: inherit;
  -moz-appearance: menulist;
}
.react-time-picker__button {
  border: 0;
  background: transparent;
  padding: 4px 6px;
}
.react-time-picker__button:enabled {
  cursor: pointer;
}
.react-time-picker__button:enabled:hover .react-time-picker__button__icon,
.react-time-picker__button:enabled:focus .react-time-picker__button__icon {
  stroke: #0078d7;
}
.react-time-picker__button:disabled .react-time-picker__button__icon {
  stroke: #6d6d6d;
}
.react-time-picker__button svg {
  display: inherit;
}
.react-time-picker__clock {
  width: 200px;
  height: 200px;
  max-width: 100vw;
  padding: 25px;
  background-color: white;
  border: thin solid #a0a096;
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1;
}
.react-time-picker__clock--closed {
  display: none;
}

@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700;800;900&family=Quicksand:wght@300;400;500;600;700&display=swap');

* {
  box-sizing: border-box;
}

body {
  margin: 0;
  /* background: #fff; */
  font-family: 'Poppins', sans-serif;
}

.headline {
  /* background: #d4d3d3; */
  border-radius: 6px;
  height: 4em;
  width: 100%;
}

.text {
  margin: 0.8em 0 0 0;
  /* background: #efefef; */
  border-radius: 6px;
  height: 1em;
  width: 100%;
}

.gallery {
  display: flex;
  box-sizing: border-box;
  background: #fae5d3;
}

.left {
  width: 50%;
}

.detailsWrapper {
  margin: auto;
  width: 60%;
}

.details {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.right {
  width: 50%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.photos {
  width: 35vw;
  height: 50vw;
  background: transparent;
  position: relative;
}

.photos-box {
  overflow: hidden;
  position: absolute;
  width: 80%;
  height: 80%;
  top: 10%;
  left: 10%;
  border-radius: 20px;
}

.photo {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

.photo img,
.scaleDiv {
  float: left;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.col1 {
  background: #f6d2b2;
}

.col2 {
  background: #c3ccf6;
}

.col3 {
  background: #aaecc6;
}

/* @media only screen and (min-width: 768px) and (max-width: 1024px) {
  .detailsWrapper {
    width: 85%;
  }
} */

@media all and (max-width: 640px) {
  .gallery {
    position: relative;
  }

  .left {
    width: 100%;
  }

  .right {
    width: 100%;
    position: absolute;
  }

  .photos {
    position: absolute;
    width: 100%;
    height: 65vh;
    bottom: 0px;
  }

  .photos-box {
    width: 90%;
    height: 90%;
    top: 0%;
    left: 5%;
    bottom: 5%;
  }

  .details {
    justify-content: start;
    padding-top: 50px;
  }
  .detailsWrapper {
    width: 85%;
  }
}

.random-position {
  position: absolute;
  top: calc(100vh * var(--random-top));
  left: calc(100vw * var(--random-left));
}

.scroll-prompt {
  position: fixed;
  z-index: 998;
  bottom: -80px;
  left: 50%;
  margin-left: -80px;
  width: 160px;
  height: 160px;

  .scroll-prompt-arrow-container {
    position: absolute;
    top: 0;
    left: 50%;
    margin-left: -18px;
    animation-name: bounce;
    animation-duration: 1.5s;
    animation-iteration-count: infinite;
  }
  .scroll-prompt-arrow {
    animation-name: opacity;
    animation-duration: 1.5s;
    animation-iteration-count: infinite;
  }
  .scroll-prompt-arrow:last-child {
    animation-direction: reverse;
    margin-top: -6px;
  }
  .scroll-prompt-arrow > div {
    width: 36px;
    height: 36px;
    border-right: 8px solid #bebebe;
    border-bottom: 8px solid #bebebe;
    transform: rotate(45deg) translateZ(1px);
  }
}

@keyframes opacity {
  0% {
    opacity: 0;
  }

  10% {
    opacity: 0.1;
  }

  20% {
    opacity: 0.2;
  }

  30% {
    opacity: 0.3;
  }

  40% {
    opacity: 0.4;
  }

  50% {
    opacity: 0.5;
  }

  60% {
    opacity: 0.6;
  }

  70% {
    opacity: 0.7;
  }

  80% {
    opacity: 0.8;
  }

  90% {
    opacity: 0.9;
  }

  100% {
    opacity: 1;
  }
}

@keyframes bounce {
  0% {
    transform: translateY(0);
  }

  10% {
    transform: translateY(3px);
  }

  20% {
    transform: translateY(6px);
  }

  30% {
    transform: translateY(9px);
  }

  40% {
    transform: translateY(12px);
  }

  50% {
    transform: translateY(15px);
  }

  60% {
    transform: translateY(18px);
  }

  70% {
    transform: translateY(21px);
  }

  80% {
    transform: translateY(24px);
  }

  90% {
    transform: translateY(27px);
  }

  100% {
    transform: translateY(30px);
  }
}
