@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: 'Open Sans';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
span {
  display: inline-block;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.firebase-emulator-warning {
  opacity: 0 !important;
}

.shop-details-container {
  background-color: #546de520;
}

/* HTML: <div class="loader"></div> */
.small-loader {
  width: 25px;
  aspect-ratio: 1;
  border-radius: 50%;
  border: 2px solid lightblue;
  border-right-color: orange;
  animation: l2 1s infinite linear;
}
@keyframes l2 {
  to {
    transform: rotate(1turn);
  }
}
